export const palette = {
  white: '#FFFFFF',
  black: '#000000',

  gray25: '#FFFFFF0F',
  gray15: '#FFFFFF0F', //'#202226',
  gray10: '#222222', // old '#141619',
  gray05: '#1C1C1C', // old '#0b0c0e',

  // new from figma,
  darkLayer0: '#18181A',
  darkLayer1: '#212124',
  darkLayer2: '#2a2a2f', // figma used #34343B but a bit too bright

  darkBorder1: '#34343B',
  darkBorder2: '#64646B',

  // Dashboard bg / layer 0 (light theme)
  gray90: '#F4F5F5',
  // Card bg / layer 1
  gray100: '#F4F5F5',
  // divider line
  gray80: '#D0D1D3',
  // from figma
  lightBorder1: '#E4E7E7',

  brand: '#01A982',

  blueDarkMain: '#00567A', // '#4165F5',
  blueDarkText: '#00567A', // '#58a6ff', //'#33a2e5', // '#5790FF',
  redDarkMain: '#A2423D',
  redDarkText: '#A2423D',
  greenDarkMain: '#01A982',
  greenDarkText: '#008567',
  orangeDarkMain: '#9B6310',
  orangeDarkText: '#9B6310',

  blueLightMain: '#00C8FF',
  blueLightText: '#00C8FF',
  redLightMain: '#FC6161',
  redLightText: '#FC6161',
  greenLightMain: '#01A982',
  greenLightText: '#17EBA0',
  orangeLightMain: '#FFBC44',
  orangeLightText: '#FFBC44',

  // blueDarkMain: '#3D71D9', // '#4165F5',
  // blueDarkText: '#6E9FFF', // '#58a6ff', //'#33a2e5', // '#5790FF',
  // redDarkMain: '#D10E5C',
  // redDarkText: '#FF5286',
  // greenDarkMain: '#1A7F4B',
  // greenDarkText: '#6CCF8E',
  // orangeDarkMain: '#FF9900',
  // orangeDarkText: '#fbad37',

  // blueLightMain: '#3871DC',
  // blueLightText: '#1F62E0',
  // redLightMain: '#E0226E',
  // redLightText: '#CF0E5B',
  // greenLightMain: '#1B855E',
  // greenLightText: '#0A764E',
  // orangeLightMain: '#FF9900',
  // orangeLightText: '#B5510D',
};
